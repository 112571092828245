export const Bio = {
    name: "Alok Sharma",
    roles: [
        "Python Developer",
        "Full Stack Developer",
        "IOT Developer",
        "Programmer",
    ],
    description:"Passionate Fullstack and Python Developer in India, specializing in Python frameworks. Committed to delivering impactful and user-friendly solutions, I focus on cutting-edge software and am currently engaged in crafting IoT-based projects. Let's build the future together!",
    github:     "https://github.com/alok-2002",
    resume:      "https://drive.google.com/file/d/1wuQGxi39mkRRbGdskfwYXrJwN4LnQV0_/view?usp=sharing",
    personalwebsite: "https://soulfulscribbles.tech",
    linkedin:   "https://www.linkedin.com/in/alok-sharma2002-",
    twitter:    "https://twitter.com/atul_sharma_20",
    insta:      "https://www.instagram.com/atul_sharma.20/",
    facebook:   "https://www.facebook.com/flarexlucifer",
  };
  
  export const skills = [
    {
      title: "Frontend",
      skills: [
        {
          name: "React Js",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
        },
        {
          name: "HTML5",
          image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
        },
        {
          name: "CSS",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
        },
        {
          name: "JavaScript",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
        },
        {
          name: "Bootstrap",
          image:
            "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
        },
        {
          name: "Material UI",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
        },
      ],
    },
    {
      title: "Backend",
      skills: [
        {
          name: "Node Js",
          image: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
        },
        {
          name: "Express Js",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original.svg",
        },
        {
          name: "Python",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
        },
        {
          name: "MySQL",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
        },
        {
          name: "MongoDB",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
        },
      ],
    },
    {
      title: "Internet Of Things",
      skills: [
        {
          name: "C/C++",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
        },
        {
          name: "Arduino",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/arduino/arduino-original.svg",
        },
        {
            name: "Arduino IDE",
            image:
              "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/arduino/arduino-original-wordmark.svg",
          },
      ],
    },
    {
      title: "Others",
      skills: [
        {
          name: "Git",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
        },
        {
          name: "GitHub",
          image:
            "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
        },
        {
          name: "Vercel",
          image:
            "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/Vercel_favicon.svg",
        },
        {
          name: "Netlify",
          image:
            "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
        },
        {
          name: "VS Code",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
        },
        {
          name: "GCP",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/googlecloud/googlecloud-original.svg",
        },
        {
          name: "AWS",
          image:
            "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
        },        
      ],
    },
  ];
  
  export const experiences = [
    {
      id: 0,
      img: "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/logoNoBg.png",
      role: "Web Developer Intern",
      company: "Bharat Intern",
      date: "June 2023 - July 2023",
      desc: "Worked on the tasks assigned to me.The tasks were to create a many web applications",
      skills: [
        "HTML",
        "CSS",
        "JavaScript",
      ],
      doc: "https://raw.githubusercontent.com/Stark-Industri/devicon/436929c1a6a22f75d8d5e8aea39e584b6d161bf0/icons/Screenshot%202024-01-11%20174233.png",
    },
    {
      id: 1,
      img: "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/tz9etEGO_400x400.jpg",
      role: "Open Source Contributor ",
      company: "Hacktoberfest 2023",
      date: "October 2023 - November 2023",
      desc: "Contributed to many different open-source projects.",
      doc: "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/Screenshot%202024-01-11%20185547.png",
    },
    {
      id: 2,
      img: "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/arcade.png",
      role: "Participant",
      company: "The Arcade By Google Cloud",
      date: "October 2023 - Present",
      desc: "Enthusiastically participated in Google Cloud's 'The Arcade' event, contributing to an immersive and collaborative tech experience. Engaged in hands-on challenges and networking, reinforcing my commitment to staying at the forefront of cloud technologies.",
      skills: [
        "Google Cloud Platform",
        "Firebase",
        "Looker Studio",
        "Virtual Machines",
        "Kubernetes",
      ],
    }, 
  ];
  
  export const education = [
    {
      id: 0,
      img: "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/mits.jpeg",
      school: "Madhav Institute of Technology and Science, Gwalior",
      date: "Oct 2021 - Sep 2025",
      grade: "7.82 CGPA",
      desc: "I am currently pursuing a Bachelor's degree in Internet Of Things at Madhav Institute Of Technology And Science, Gwalior. I have completed 5 semesters and have a CGPA of 7.82. I have taken courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, and mainly some courses related my branch like IoT Architecture And Protocols, Basics Of IoT, among others. I am also a member of the The Institution of Electronics and Telecommunication Engineers(IEIE) at MITS.",
      degree: "Bachelor of Technology - Internet Of Things",
    },
    {
      id: 1,
      img: "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/school.jpeg",
      school: "Ebenezer Higher Secondary School, Gwalior",
      date: "Apr 2019 - Apr 2021",
      grade: "75 %",
      desc: "I completed my class 12 high school education at Ebenezer Higher Secondary School, Gwalior, where I studied Science with Mathematics.",
      degree: "CBSE (XII), Science with Mathematics",
    },
    
  ];
  
  export const projects = [
    {
      id: 8,
      title: "Python Cloud Flow",
      date: "June 2023 - September 2023",
      description:
        "Python Cloud Flow is a versatile Python app that empowers users to seamlessly upload files to various cloud storage services, including Google Drive, Dropbox, Box.com, pCloud, and Amazon S3. The script offers a modern graphical user interface (GUI) built using the tkinter library, making it convenient and user-friendly for both beginners and experienced users.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/pycloudflow.png",
      tags: [
        "Google Drive API",
        "AWS S3 API",
        "Dropbox API",
        "Box.com API",
        "pCloud API",
        "Python",
        "Tkinter",
        "Google Auth",
      ],
      category: "desktop app",
      github: "https://github.com/alok-2002/PythonCloudFlow",
      webapp: "https://github.com/Alok-2002/PythonCloudFlow",
    },
    {
      id: 0,
      title: "Quiz Whiz",
      date: "Apr 2023 - May 2023",
      description:
        "Crafted a dynamic Quiz Web App crafted with HTML, CSS, and JavaScript, leveraging the Open Trivia Database for an extensive range of questions. Offering an engaging experience, users can assess their knowledge across diverse categories through interactive quizzes. Test your skills and learn with every question!",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/quiz.png",
      tags: ["HTML", "CSS", "Javascript", "Open Trivia Database", "Open Trivia API"],
      category: "web app",
      github: "https://github.com/alok-2002/quizzify",
      webapp: "https://quizwhiz.vercel.app/",
    },
    {
      id: 9,
      title: "Advanced Student Management System",
      date: "Apr 2023 - May 2023",
      description:
        "The Student Management System (SMS) is a GUI application created with Python and tkinter. It is designed to manage student data for a school or university. This version of the system utilizes a MySQL database to store and retrieve student information, such as names, addresses, contact information, and academic records.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/sms.png",
      tags: ["Python", "MySQL", "Tkinter", "CRUD Application", "Database"],
      category: "desktop app",
      github: "https://github.com/Alok-2002/Advanced-Student-Management-System",
      webapp: "https://github.com/Alok-2002/Advanced-Student-Management-System",
    },
    {
      id: 1,
      title: "Temperature Wizard",
      date: "June 2023 - July 2023",
      description:
        "Designed and developed the Temperature Wizard is a web-based temperature calculator application built using HTML, CSS, and JavaScript. It allows users to convert temperatures between different units, such as Celsius, Fahrenheit, and Kelvin.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/Screenshot%202024-02-03%20124800.png",
      tags: [
        "HTML",
        "CSS",
        "Javascript",
      ],
      category: "web app",
      github: "https://github.com/alok-2002/Temperature_Wizard",
      webapp: "https://temperaturewizard.vercel.app/",
    },
    {
      id: 2,
      title: "Vehicle Detection And Counter",
      date: "October 2023 - Nov 2023",
      description:
        "The app utilizes the OpenCV library for image processing and matplotlib for plotting the vehicle count over time.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/veh.png",
      tags: ["Python", "OpenCV", "Matplotlib", "Numpy"],
      category: "machine learning",
      github: "https://github.com/Alok-2002/Vehicle_Detector_And_Counter",
      webapp: "https://github.com/Alok-2002/Vehicle_Detector_And_Counter",
    },
    {
      id: 3,
      title: "Resume Wizard",
      date: "June 2023 - July 2023",
      description:
        "Resume Wizard is a web application designed to help users create professional and polished resumes effortlessly. With its user-friendly interface and intuitive design, anyone can easily generate a visually appealing resume in minutes",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/resume.png",
      tags: ["HTML", "CSS", "Javascript",],
      category: "web app",
      github: "https://github.com/alok-2002/resume_wizard",
      webapp: "https://resume-grow.vercel.app/",
    },
    {
      id: 10,
      title: "Code Vault App",
      date: "March 2023 - April 2023",
      description:
        "Code Vault is a Windows application that provides encryption and decryption of text using the Base64 algorithm and a secret key. This app allows users to securely store and share sensitive information by encoding it into Base64 format with a secret key.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/code.png",
      tags: ["Python", "Tkinter", "Base64", "Cryptography"],
      category: "desktop app",
      github: "https://github.com/Alok-2002/Code_Vault_For_Windows",
      webapp: "https://github.com/Alok-2002/Code_Vault_For_Windows/releases/download/CodeVault/CodeVault-WINDOWS_1.5_setup_x64_x86.exe",
    },
    {
      id: 4,
      title: "Image Steganography App",
      date: "July 2023 - August 2023",
      description:
        "This app allows you to hide confidential text messages within images using the concept of steganography. Steganography is the art of hiding information within another seemingly innocuous medium, such as an image.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/image.png",
      tags: ["Python", "Tkinter", "Steganography", "Desktop App"],
      category: "desktop app",
      github: "https://github.com/Alok-2002/Image_Steganography_App_Using_Python",
      webapp: "https://github.com/Alok-2002/Image_Steganography_App_Using_Python",
    },
    {
      id: 5,
      title: "Todo Web App",
      date: "Jun 2023 - July 2023",
      description:
        " A Todo Web App made with HTML, CSS And Javascript. It has Local Storage where users can store their todos.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/todo.png",
      tags: ["HTML", "CSS", "Javascript", "Local Storage"],
      category: "web app",
      github: "https://github.com/Alok-2002/ToDo_Express",
      webapp: "https://to-doexpress.vercel.app/",
    },
    {
      id: 6,
      title: "Calculator Wizard",
      date: "Jun 2023 - July 2023",
      description:  
        "A simple calculator project made using HTML, CSS, and JavaScript.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/calci.png",
      tags: ["HTML", "CSS", "Javascript",],
      category: "web app",
      github: "https://github.com/Alok-2002/Calculator_Wizard",
      webapp: "https://calculator-wizard.vercel.app/",
    },
    {
      id: 7,
      title: "Smart Alcohal Detection And Engine Locking System",
      date: "August 2023 - November 2023",
      description:
        "The Smart Alcohol Detection and Engine Locking System is an innovative project aimed at enhancing road safety by preventing alcohol-impaired driving. This repository contains the complete details, documentation, and source code for building and implementing the system.",
      image:
        "https://raw.githubusercontent.com/Stark-Industri/devicon/master/icons/project1.jpg",
      tags: ["Arduino", "C/C++", "Engine Locking", "Hardware Project", "Alcohol Detector", " Smart System", "IoT Project"],
      category: "iot project",
      github: "https://github.com/Alok-2002/Smart_Alcohol_Detection_And_Engine_Locking_Project",
      webapp: "https://github.com/Alok-2002/Smart_Alcohol_Detection_And_Engine_Locking_Project",
      member: [
        {
          name: "Alok Sharma",
          img: "https://avatars.githubusercontent.com/u/139451198?s=400&u=b83a23378aa74f2ea19af8f91d52f4914c9d5e98&v=4",
          linkedin: "https://www.linkedin.com/in/alok-sharma2002-/",
          github: "https://github.com/alok-2002/",
        },
        {
          name: "Sanskriti Yadav",
          img: "https://avatars.githubusercontent.com/u/139451838?s=400&u=1c4239c0f0ef6aae4d6a077d736a2da5bfdc4e01&v=4",
          linkedin: "https://www.linkedin.com/in/Sanskriti-Yadav/",
          github: "https://github.com/sanskriti-yadav",
        },
      ],
    },
  ];
  
  export const TimeLineData = [
  { year: 2021, text: "Started my journey" },
  { year: 2022, text: "Learned Basic C/C++, Arduino Programming" },
  { year: 2023, text: "Started Learning Python" },
  { year: 2023, text: "Shared my projects with the world" },
];
  